<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Pricing Layout</h2>
    </div>
    <!-- BEGIN: Pricing Layout -->
    <div class="intro-y box flex flex-col lg:flex-row mt-5">
      <div class="intro-y flex-1 px-5 py-16">
        <CreditCardIcon
          class="w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"
        />
        <div class="text-xl font-medium text-center mt-10">Basic Plan</div>
        <div class="text-gray-700 dark:text-gray-600 text-center mt-5">
          1 Domain <span class="mx-1">•</span> 10 Users
          <span class="mx-1">•</span> 20 Copies
        </div>
        <div
          class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </div>
        <div class="flex justify-center">
          <div class="relative text-5xl font-semibold mt-8 mx-auto">
            35
            <span
              class="absolute text-2xl top-0 right-0 text-gray-500 -mr-4 mt-1"
              >$</span
            >
          </div>
        </div>
        <button
          type="button"
          class="button button--lg block text-white bg-theme-1 rounded-full mx-auto mt-8"
        >
          PURCHASE NOW
        </button>
      </div>
      <div
        class="intro-y border-b border-t lg:border-b-0 lg:border-t-0 flex-1 p-5 lg:border-l lg:border-r border-gray-200 dark:border-dark-5 py-16"
      >
        <BriefcaseIcon
          class="w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"
        />
        <div class="text-xl font-medium text-center mt-10">Business</div>
        <div class="text-gray-700 dark:text-gray-600 text-center mt-5">
          1 Domain <span class="mx-1">•</span> 10 Users
          <span class="mx-1">•</span> 20 Copies
        </div>
        <div
          class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </div>
        <div class="flex justify-center">
          <div class="relative text-5xl font-semibold mt-8 mx-auto">
            60
            <span
              class="absolute text-2xl top-0 right-0 text-gray-500 -mr-4 mt-1"
              >$</span
            >
          </div>
        </div>
        <button
          type="button"
          class="button button--lg block text-white bg-theme-1 rounded-full mx-auto mt-8"
        >
          PURCHASE NOW
        </button>
      </div>
      <div class="intro-y flex-1 px-5 py-16">
        <ShoppingBagIcon
          class="w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"
        />
        <div class="text-xl font-medium text-center mt-10">Enterprise</div>
        <div class="text-gray-700 dark:text-gray-600 text-center mt-5">
          1 Domain <span class="mx-1">•</span> 10 Users
          <span class="mx-1">•</span> 20 Copies
        </div>
        <div
          class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </div>
        <div class="flex justify-center">
          <div class="relative text-5xl font-semibold mt-8 mx-auto">
            120
            <span
              class="absolute text-2xl top-0 right-0 text-gray-500 -mr-4 mt-1"
              >$</span
            >
          </div>
        </div>
        <button
          type="button"
          class="button button--lg block text-white bg-theme-1 rounded-full mx-auto mt-8"
        >
          PURCHASE NOW
        </button>
      </div>
    </div>
    <!-- END: Pricing Layout -->
    <!-- BEGIN: Pricing Layout -->
    <div class="intro-y box flex flex-col lg:flex-row mt-5">
      <div class="intro-y flex-1 px-5 py-16">
        <ActivityIcon
          class="w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"
        />
        <div class="text-xl font-medium text-center mt-10">Basic Plan</div>
        <div class="text-gray-700 dark:text-gray-600 text-center mt-5">
          1 Domain <span class="mx-1">•</span> 10 Users
          <span class="mx-1">•</span> 20 Copies
        </div>
        <div
          class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </div>
        <div class="flex justify-center">
          <div class="relative text-5xl font-semibold mt-8 mx-auto">
            35
            <span
              class="absolute text-2xl top-0 right-0 text-gray-500 -mr-4 mt-1"
              >$</span
            >
          </div>
        </div>
        <button
          type="button"
          class="button button--lg block text-white bg-theme-1 rounded-full mx-auto mt-8"
        >
          PURCHASE NOW
        </button>
      </div>
      <div
        class="intro-y border-b border-t lg:border-b-0 lg:border-t-0 flex-1 px-5 lg:border-l lg:border-r border-gray-200 dark:border-dark-5 py-16"
      >
        <BoxIcon class="w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto" />
        <div class="text-xl font-medium text-center mt-10">Business</div>
        <div class="text-gray-700 dark:text-gray-600 text-center mt-5">
          1 Domain <span class="mx-1">•</span> 10 Users
          <span class="mx-1">•</span> 20 Copies
        </div>
        <div
          class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </div>
        <div class="flex justify-center">
          <div class="relative text-5xl font-semibold mt-8 mx-auto">
            60
            <span
              class="absolute text-2xl top-0 right-0 text-gray-500 -mr-4 mt-1"
              >$</span
            >
          </div>
        </div>
        <button
          type="button"
          class="button button--lg block text-white bg-theme-1 rounded-full mx-auto mt-8"
        >
          PURCHASE NOW
        </button>
      </div>
      <div class="intro-y flex-1 px-5 py-16">
        <SendIcon class="w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto" />
        <div class="text-xl font-medium text-center mt-10">Enterprise</div>
        <div class="text-gray-700 dark:text-gray-600 text-center mt-5">
          1 Domain <span class="mx-1">•</span> 10 Users
          <span class="mx-1">•</span> 20 Copies
        </div>
        <div
          class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </div>
        <div class="flex justify-center">
          <div class="relative text-5xl font-semibold mt-8 mx-auto">
            120
            <span
              class="absolute text-2xl top-0 right-0 text-gray-500 -mr-4 mt-1"
              >$</span
            >
          </div>
        </div>
        <button
          type="button"
          class="button button--lg block text-white bg-theme-1 rounded-full mx-auto mt-8"
        >
          PURCHASE NOW
        </button>
      </div>
    </div>
    <!-- END: Pricing Layout -->
  </div>
</template>
